import MetaInfoUseCase from '../app/metaApi/usecase/metaInfo';
import { isSsr } from '@/helpers/DetectSSR.js';

export default {
  data() {
    return {
      // overwrite searchMetaInfo later on the component
      metaInfo: '',
      searchMetaInfo: 'default',
    };
  },
  methods: {
    async getMetaInfoNew({ channel }) {
      let data = {};
      try {
        const d = await MetaInfoUseCase.getMeta(this.searchMetaInfo);
        data = d.data
        this.metaInfo = d.data
      } catch (error) {
        console.log(error);
      }
      return {
        // main SEO
        title: data.meta_info_title,
        meta: [
          // main SEO
          {
            name: 'description',
            content: data.meta_info_description,
          },
          {
            name: 'keywords',
            content: data.meta_info_keyword,
          },
          // OG Facebook
          {
            property: 'og:title',
            content: data.meta_info_title,
          },
          {
            property: 'og:description',
            content: data.meta_info_description,
          },
          {
            property: 'article:tag',
            content: data.meta_info_keyword,
          },
          {
            property: 'article:section',
            content: channel,
          },
          {
            property: 'article:published_time',
            content: data.meta_info_publish_time,
          },
          {
            property: 'og:url',
            content: !isSsr() && window.location.href,
          },
          {
            property: 'og:image',
            content: data.attachment_meta_info_photo_url,
          },
          // Twitter Card
          {
            name: 'twitter:title',
            content: data.meta_info_title,
          },
          {
            name: 'twitter:description',
            content: data.meta_info_description,
          },
          {
            name: 'twitter:image:src',
            content: data.attachment_meta_info_photo_url,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: !isSsr() && window.location.href,
          },
        ],
      };
    },
    getMetaInfo() { // old meta
      MetaInfoUseCase.getMeta(this.searchMetaInfo)
        .then((resp) => {
          if (!resp.err && resp.data) {
            this.metaInfo = resp.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getMetaInfo()
  }
};
